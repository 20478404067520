.home-container {
    margin-top: 50px;
    padding: 0 80px;
}

.home-container_subtitle {
    color: var(--color-verdeoscuro);
    font-size: 50px;
    padding: 35px 0px;
}

.home-container_brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.home-container_brandsEn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media (max-width: 768px) {
    .home-container {
        padding: 0 20px !important;
    }

    .home-container_subtitle {
        font-size: 35px !important;
        margin-left: 40px;
    }
}