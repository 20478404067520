.blog-container {
    padding: 0 80px;
    display: grid;
    place-items: center;
    margin-bottom: 20px;
}

.blog-button {
    color: var(--color-verdeoscuro) !important;
}

@media (max-width: 768px) {
    .blog-container {
        padding: 0 20px;
        min-height: 100vh;
    }
}