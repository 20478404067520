body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  --color-verdeoscuro: #315F56;
  --color-gris: #616260;
  --color-verdeclaro: #99C1B9;
  --color-beige: #EFEDE7;
}
