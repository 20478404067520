.space_rightS {
    padding: 80px 35px 0 35px;
    background: #fff;
}

.space-container_rightS {
    display: grid;
    grid-template-columns: 55% 45%;
    margin: 10px auto;
    max-width: 1500px;
}

.space-text_rightS {
    position: relative;
    background: var(--color-beige);
    padding: 50px 0;
}

.container-h3_rightS {    
    width: 80%;
    margin: 10px auto;
    color: var(--color-verdeoscuro);
    font-size: 60px;
}

.space-image_rightS {
    display: grid;
    place-items: center;
    position: relative;
    left: 100;
    z-index: 1000;
}

.space-image_rightS img{
    width: 100%;
}

.container-hr_rigtS {
    width: 100px;
    border: 4px solid var(--color-verdeoscuro);
    display: table-caption;
}

.container-pgh_rightS {
    width: 80%;
    margin: auto;
    color: #000;
    font-weight: 300;
    font-size: 28px;
}


@media (max-width: 768px) {
    .space_rightS {
        padding: 30px 10px;
    }

    .space-container_rightS {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .space-text_rightS {
        margin: 0;
    }

    .space-text_contS {
        margin-top: 15px;
    }

    .container-h3_right, .container-pgh_rightS {
        width: auto;
    }

    .container-h3_rightS {
        font-size: 30px;
    }

    .container-pgh_rightS {
        font-size: 20px;
        font-weight: 300;
        padding: 0 20px;
    }

    .container-hr_rigtS {
        width: 100px;
        border: 3px solid var(--color-verdeoscuro);
        display: table-caption;
    }
}