footer {
    background: var(--color-verdeoscuro);
    box-sizing: border-box;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-family: 'Noto Sans', sans-serif;
    min-height: 350px;
}

.footer-icon {
    width: 50px !important;
    margin: 0 !important;
}

.coryright {
    place-self: center;
    width: auto;
    margin: auto;
}

.coryright p {
    margin: 30px 0;
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
}


.contact-info {
    place-self: center;
    width: auto;
    margin: auto;
    font-family: 'Noto Sans', sans-serif;
}

.contact-social {
    margin-top: 30px;
}

.contact-info p {
    margin: 20px auto;
}

.contact-info h3 {
    margin: 10px 0 0 0;
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 30px;
}

.contact-info a {
    text-decoration: none;
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
}

.contact-info a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.newsletter {
    display: flex;
    place-items: center;
    justify-content: center;
}

.newsletter p {
    color: #FFF;
    font-size: 24px;
    margin: auto;
}

.newsletter input {
    display: block;
    margin: 15px 0;
    border: none;
    height: 35px;
    width: 100%;
    border-radius: 5px;
}

.newsletter-button {
    font-size: 16px;
    background: #eaebed;
    color: var(--color-gris);
    cursor: pointer;
}

@media (max-width: 700px) {
    footer {
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        grid-template-rows: auto auto auto;
        padding: 25px 0;
    }

    .coryright * {
        margin: 8px 0 !important;
    }

    .contact-info * {
        margin: 3px 0 !important;
        padding: 0 !important;
    }

    .coryright .div , .contact-info .div, .newsletter .div {
        width: 265px;
    }

    .coryright, .contact-info, .newsletter {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
