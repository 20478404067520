.history-hero {
    display: grid;
    place-items: center;
    margin: 40px auto;
}

.history-hero h2 {
    margin: 0 auto;
    font-size: 80px;
    font-weight: bold;
}

.history-hero h3 {
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .history-hero h2 {
        font-size: 60px;
    }

    .history-hero h3 {
        font-size: 30px;
    }
}