.cert-container {
    max-width: 500px;
    margin: 24px 50px;
}

.cert-container img {
    height: 130px;
}

.cert-container p {
    font-size: 20px;
    font-weight: 300;
    text-align: justify;
}

.cert-container h2 {
    margin: 0;
    color: var(--color-verdeoscuro);
    font-size: 32px;
}

@media (max-width: 768px) {
    .cert-container {
        margin: 20px;
    }

    .cert-container img {
        height: 110px;
    }
    
    .cert-container p {
        font-size: 18px !important;
    }
}