
.text-box {
    margin: 55px auto 0 auto;
    display: grid;
    place-content: center;
    padding: 80px 180px;
}

.text-box_p {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    max-width: 915px;
}

.text-box_hr {
    height: 8px;
    width: 350px;
    background: var(--color-verdeclaro);
    border: none;
}

@media (max-width: 768px) {
    .text-box {
        padding: 20px 0 !important;
        margin: 0 auto;
        width: 100% !important;
    }

    .text-box_p {
        font-size: 20px !important;
        font-weight: 400 !important;
        padding: 0 12px;
    }

    .text-box_hr {
        width: 180px !important;
        height: 5px;
    }
}