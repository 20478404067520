@font-face {
  font-family: 'Americana Std Extra Bold';
  src: url('../../../fonts/Americana\ Std\ Extra\ Bold.otf') format('opentype');
}

.contact-form-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
  h1{
    font-size: 38px;
    text-align: center;
    color: var(--color-verdeoscuro);

  }
  
  .form-field {
    margin-bottom: 15px;
    text-align: left;
  }
  
  label {
    display: block;
    font-weight: bold;
    color:#ac0a0a;
  }
  
  input[type="text"],
  input[type="email"] {
    max-width: 550px;
    width: 100%;
    padding: 10px;
    border-color: #5e5c5c7b;

    margin-top: 3px;
    border-radius: 10px; /* Añadir bordes redondeados */
  }
  
  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
    font-size: 25px;
    color: #777; /* Color de placeholder */
  }
  
  .submit-button {
    background: var(--color-verdeoscuro);
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 30px; /* Añadir bordes redondeados al botón */
  }
  
  .success-alert {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px; /* Añadir bordes redondeados al mensaje de éxito */
  }

  .text{
    text-align: center;
    font-size: 250px;
  }
  
  .formTitle{
    font-size: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    font-weight: 700; /* Aplicar negrita */
  }


  @media (max-width: 767px) {

  .formTitle {
    font-size: 30px;
  }

  input[type="text"],
  input[type="email"] {
    max-width: 400px;
    width: 100%;
    padding: 5px;
    border-color: #5e5c5c7b;

    margin-top: 3px;
    border-radius: 10px;
    /* Añadir bordes redondeados */
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
    font-size: 15px;
    color: #777;
    /* Color de placeholder */
  }
  }