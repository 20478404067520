.space {
    padding: 10px 35px;
}

.space-container {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 10px auto;
    max-width: 1500px;
}

.space-image {
    padding: 50px 0;
}

.space-image img{
    width: 100%;
}

.space-text {
    display: grid;
    place-items: center;
    padding: 50px 0;
}

.space-text div {
    max-width: 800px;
}

.container-h3 {    
    width: 70%;
    margin: auto;
    color: var(--color-verdeoscuro);
    padding-bottom: 20px;
    font-size: 40px;
}

.container-hr {
    width: 100px;
    border: 4px solid var(--color-verdeoscuro);
    display: table-caption;
}

.container-pgh {
    width: 70%;
    margin: auto;
    color: #000;
    font-size: 25px;
    font-weight: 300;
}

@media (max-width: 768px) {
    .space {
        padding: 30px 10px;
    }

    .container-h3 {
        width: auto;
        font-size: 30px;
    }
    
    .container-hr {
        width: 100px;
        border: 3px solid var(--color-verdeoscuro);
        display: table-caption;
    }

    .space-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .space-text {
        margin-top: 15px;
        padding: 15px 0px;
    }

    .space-image {
        display: grid;
        place-items: center;
        padding: 20px 0 !important;
    }

    .space-image img {
        width: 100%;
    }

    .container-pgh {
        width: 100%;
        font-size: 20px !important;
    }

}
