.sustentability-container {
  margin-top: 50px;
  padding: 0 80px;
}

.section-cert {
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: center;
}

.section-info-p {
  display: flex;
  justify-content: center;
  gap: 0 20px;
  margin: 80px auto;
}

.section-info-p_left {
  width: 500px;
  font-size: 25px;
  font-weight: 400;
}

.section-info-p_right {
  width: 500px;
  font-size: 25px;
  font-weight: 400;
}
.section-i_bottom {
  max-width: 800px;
  margin: 0 auto;
  font-size: 25px;
  font-weight: 400;
  text-align: center !important;
  padding: 50px;
}

.section-silla-eco {
  background-color: var(--color-verdeclaro);
  display: flex;
  justify-content: flex-end;
  padding: 65px 0;
}

.section-silla-eco img {
  max-height: 1200px;
}

.silla-eco-text {
  position: relative;
  z-index: 1000;
}

.silla-eco-text_top {
  position: relative;
  left: 220px;
}

.silla-eco-text_mid {
  position: relative;
  left: 60px;
  padding-top: 80px;
}

.silla-eco-text_bot {
  position: relative;
  left: 220px;
  padding-top: 80px;
}

.cert-container p {
  font-size: 22px;
  font-weight: 300;
}

@media (max-width: 1100px) {
  .section-silla-eco {
    display: grid !important;
    grid-template-columns: 100% !important;
    grid-template-rows: 50% 50% !important;
  }

  .silla-eco-text_top,
  .silla-eco-text_mid,
  .silla-eco-text_bot {
    left: 0;
  }
}

@media (max-width: 810px) {
  .section-silla-eco img {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .sustentability-container {
    margin-top: 30px;
    padding: 0 20px;
  }

  .section-cert {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 10px;
  }

  .section-info-p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
    margin-top: 0;
  }

  .section-info-p_left,
  .section-info-p_right {
    font-size: 20px;
  }

  .cert-container p {
    font-size: 20px;
  }

  .section-silla-eco {
    padding-bottom: 10px;
  }

  .section-silla-eco img {
    place-self: end;
  }
}
