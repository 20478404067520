.faq {
  max-width: 100%;
}

.faq-item {
  margin-bottom: 20px;
}
.main{
  padding: 25px;

}

@media (max-width: 768px) {
  .faq {
    padding: 10px;
  }
  .faq-item {
    margin-bottom: 10px;
  }
}
.faq {
  max-width: 100%;
}
.faq h2 {
  font-size: 35px;
  color: var(--color-verdeoscuro);
  margin-bottom: 50px;
  grid-column: 1;
  justify-self: start;
  font-family: 'Custom Font', sans-serif;
  }



@media (max-width: 768px) {
}
@font-face {
  font-family: 'Custom Font';
  src: url('/src/fonts/Americana Std Extra Bold.otf') format('opentype'); 
}
