.timeline-container {
    padding: 0 25px;
    margin-bottom: 50px;
}

.vertical:before {
    width: 10px;
}

.vertical {
    max-width: 90%;
}

.timeline-box {
    margin: 0 !important;
}

.datefont-personal {
    color: #000 !important;
    font-size: 25px !important;
    font-weight: bold !important;
}

.vertical-timeline-element-content {
    box-shadow: none !important;
    padding: 0 !important;
}

.vertical-timeline-element--left p{
    text-align: right !important;
}

.vertical-timeline-element--left h2{
    text-align: right !important;
}

.image-left {
    flex-direction: row-reverse;
}

.vertical-timeline-element-p {
    margin: 0 !important;
    width: 200px;
    text-align: start !important;
}

.container-info {
    display: grid;
    place-items: center;
}

.container-info_title {
    width: 100%;
    height: inherit;
}

.vertical-timeline-element--left .container-info_title {
    width: 100%;
    display: flex; 
    justify-content: flex-end;
}

.info-title {
    margin: 0;
    text-align: start;
    font-size: 30px !important;
}

.container-p {
    height: 100%;
}

.vertical-timeline-element-title {
    width: auto !important;
}

.image-square {
    height: 250px;
}

.image-square img {
    height: 250px;
}

.container-info-time {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

@media (max-width: 768px) {
    .timeline-container {
        padding: 0;
    }
    
    .container-info-time {
        margin: 30px auto;
    }

    .container-info-time {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 40% 60%;
    }

    .container-logo {
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
    }
    
    .container-p {
        width: 100%;
        justify-content: flex-start;
    }

    .info-title {
        justify-self: flex-start;
    }

    .vertical-timeline-element--left p {
        text-align: left !important;
    }

    .container-info_title {
        justify-content: flex-start !important;
    }

    .image-square  img {
        width: 280px !important;
        height: auto;
    }
}