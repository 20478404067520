.space_right {
    padding: 80px 35px;
    background: #fff;
}

.space-container_right {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 10px auto;
    max-width: 1500px;
}

.space-image_right img{
    width: 100%;
}

.space-text_right {
    display: grid;
    place-items: center;
    margin-left: 5px;
    background: #fff;
}

.space-text_right div {
    max-width: 800px;
}

.container-h3_right {    
    width: 70%;
    margin: auto;
    color: var(--color-verdeoscuro);
    padding-bottom: 20px;
    font-size: 40px;
}

.container-hr_rigt {
    width: 100px;
    border: 4px solid var(--color-verdeoscuro);
    display: table-caption;
}

.container-pgh_right {
    width: 70%;
    margin: auto;
    color: #000;
    font-weight: 300;
    font-size: 22px;
}



@media (max-width: 768px) {
    .space_right {
        padding: 30px 10px;
    }

    .space-container_right {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .space-text_right {
        margin: 0;
    }

    .space-text_cont {
        margin-top: 15px;
    }

    .container-h3_right, .container-pgh_right {
        width: auto;
    }

    .container-h3_right {
        font-size: 30px;
    }

    .container-pgh_right {
        font-size: 20px;
        font-weight: 300;
        padding: 0 20px;
    }

    .container-hr_rigt {
        width: 100px;
        border: 3px solid var(--color-verdeoscuro);
        display: table-caption;
    }
}