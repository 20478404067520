.navbar-bg {
    background: #FFF !important;
}

.colorLetter {
    color: var(--color-gris) !important;
    font-weight: bold !important;
}

.nav-links {
    justify-content: space-evenly;

}