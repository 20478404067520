@font-face {
  font-family: 'Americana Std Extra Bold';
  src: url('../../../fonts/Americana\ Std\ Extra\ Bold.otf') format('opentype');
}

.lease {
  max-width: 100%;
}

.lease-item {
  margin-bottom: 20px;
}
.lease-main {
  padding: 25px 70px;
}
.lease-main a {
  padding-bottom: 100px;
  font-size: 25px;
}
.title1{
  font-size: 50px;
  color: var(--color-verdeoscuro);
  justify-self: start;
  font-weight: bold; /* Set font weight to bold */
}

.subtitle1{
  font-size: 40px;
  color: var(--color-verdeoscuro);
  justify-self: start;
  font-weight: bold; /* Set font weight to bold */
}

.text1{
  font-size: 27px;
  justify-self: start;
}

@media (max-width: 768px) {
  .lease {
    padding: 10px;
    max-width: 100%;
  }
  .lease-main {
    padding: 25px;
  }
  .lease-item {
    margin-bottom: 10px;
  }
}

.lease h2 {
  font-size: 40px;
  color: var(--color-verdeoscuro);
  grid-column: 1;
  justify-self: start;
  font-weight: bold; /* Set font weight to bold */
}

/*Modificacion de banner de beneficios*/
.Padding{
  padding-top: 10px;
}

.backgroundBeneficios{
  background-color: var(--color-verdeoscuro);
}

.title{
  text-align: left;
  font-weight: bold;
  font-size: 45px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  font-weight: bold; /* Aplicar negrita */
}

.imgLease{
  max-width: 100%;
   /* Establece el ancho máximo al 100% del contenedor */
  height: auto;
   /* Permite que la altura se ajuste automáticamente */
}

.plus{
  font-size: 45px;
  text-decoration: none;
  color: white;
}

.text{
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
}

@media (max-width: 767px) {

  .plus{
    font-size: 30px;
    text-decoration: none;
    color: white;
  }
  
  .text{
    font-size: 20px;
    text-align: left;
  }
  
  .title1{
    font-size: 30px;
  }
  
  .subtitle1{
    font-size: 30px;
  }
  
  .text1{
    font-size: 20px;
  }

}

a {
  text-decoration: none;
}
